



export default ({ app, $axios, store, route, redirect,req,res}) => {
  const userAgent = process.client ? navigator.userAgent : req.headers['user-agent'];
  const isMobile = /Mobi|Android|iphone|iPad|iPod|Windows Phone|webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (isMobile) {
      // 重定向到移动端网站
    redirect(`https://new.hankahuanbao.com/m.html`);
    return true;
  }  
    
  //第一次进入页面或者刷新页面是在server端运行，之后的路由跳转是在client端运行
  if(!route.name){
     redirect('/');
  }

  //console.log("process",process.server,process.client,process.static)
}
